export const CLIENT_TITLE = 'Client'
export const CLIENTS_LIST_TITLE = 'Clients'
export const INVESTOR_TITLE = 'Investment Profile'
export const INVESTORS_LIST_TITLE = 'Investment Profiles'
export const DISTRIBUTION_INSTRUCTIONS_TITLE = 'Distribution Instructions'
export const STEP_TITLES = {
  CLIENT: `Select ${CLIENT_TITLE}`,
  SHOW_CLIENT: `Show ${CLIENT_TITLE}`,
  SELECT_INVESTOR: `Select ${INVESTOR_TITLE}`,
  SELECT_OFFERING: 'Select Offering',
  UNIT_CLASS: 'Unit Class',
  SURVEY: `Investor Questionnaire`,
  REG_BI: 'Reg BI Form',
  ATTEMPT_TYPE_506C: '506(c) Accreditation Type',
  ATTEMPT_DETAILS_506C: '506(c) Details',
  WISEMAN_STEP: '506(c)',
  INVESTOR_ACCREDITATION: `${INVESTOR_TITLE} Accreditation`,
  INVESTOR_DOCUMENTS: `${INVESTOR_TITLE} Documents`,
  INVESTOR_DETAILS: `${INVESTOR_TITLE} Details`,
  ASSOCIATED_PERSON: 'Associated Person',
  ASSOCIATED_PERSON_IRA: 'IRA Owner',
  BENEFICIARY_PERSON: 'Beneficiary Person',
  CUSTODIAN: 'Custodian',
  AMOUNT: 'Investment Amount',
  AMOUNT_IOI2: 'Indication of Interest Amount',
  PAYMENT_METHOD: 'Payment Method',
  DISTRIBUTION_INSTRUCTIONS: DISTRIBUTION_INSTRUCTIONS_TITLE,
  OFFERING_SURVEY: 'FINRA Required',
  OFFERING_ADDITIONAL_SURVEY: 'FINRA Required Part 2',
  OFFERING_SECOND_ADDITIONAL_SURVEY: 'IPP Survey',
  AGREEMENT: 'Subscription Agreement',
  ASSIGNING_ADVISORS: 'Assigning Advisors (RIAs/Reps)',
  EMAIL_AUTHORIZATION: 'Email Authorization',
  SIGNATURE_FLOW: 'Signatures',
  COMPLETED: 'Completed',
  IPP_FORM: 'Investor Profile Form',
}

export const OFFERING_ONBOARDING_STEP_TITLES = {
  ISSUER_INFORMATION: 'Issuer Information',
  SECURITY_DETAILS: 'Security Details',
  ASSOCIATED_PERSON: 'Associated person',
  OFFERING_INFORMATION: 'Offering Information',
  REVIEW_YOUR_OFFERING: 'Review Your Offering',
  SUBSCRIPTION_AGREEMENT: 'Subscription Agreement Template',
  REVIEW_OFFERING: 'Review Your Offering',
}

export const RESEND_EMAIL_ALERT = 'We have sent you a new letter, please check your mail'
export const CONFIRM_EMAIL_ALERT =
  'Please complete the registration process by confirming your email'

export const ERROR_CONFIRM_EMAIL = 'Please confirm your email to access current functionality'
export const ACTION_RESEND_CONFIRMATION_EMAIL = 'Resend the confirmation letter'

export const OFFERING_RESTRICTED_MESSAGE =
  'In order to see the details and invest you must be registered and logged in as an accredited investor.'

export const OFFERING_RESTRICTED_BY_ROLES =
  'You must have an Investor role to Invest in current offering. Investor role can be added in My Account section'

export const OFFERING_CONFIRM_EMAIL = 'Confirm your email to invest in current offering'

export const REMOVE_DRAFT_SUCCESS_MESSAGE = 'Draft has been successfully removed'

export const RESEND_NOT_SIGNED_SUCCESS_MESSAGE = 'We have sent subscription agreement to the client'

export const OFFERINGS_DOCUMENTS_SECTION_NAME = 'offering-documents'

export const DECLINE_REQUEST_SUCCESS_MESSAGE = 'Request has been successfully declined'

export const PO_BOX_TEXT =
  'Cannot be a P.O. Box. Please use your residential address here. This is used to confirm your identity.'

export const WCODE_KEY = 'wcode'

export const WCODES_INVESTORS_LIST_TITLE = 'Wcodes for Investor'
export const WCODES_WHOLESALERS_LIST_TITLE = 'Wcodes of Wholesaler'
export const WCODES_INVESTMENTS_LIST_TITLE = 'Wcodes Investments'
export const WCODES_LICENSEE_RESTRICTION_TITLE = 'Licensee Restriction by Wcodes'
export const CRD_LICENSEE_RESTRICTION_TITLE = 'Licensee Restriction by CRD# numbers'
